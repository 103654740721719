.sbl-circ {
  position: absolute;
  display: inline-block;
  border: 2px solid;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  color: #718096;
  border-top-color: transparent;
  animation: rotate 1s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
